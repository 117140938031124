import SplitscreenGrid from "../splitscreenGrid";
import GridItem from "../gridItem";
import NarrowcastingView from "../narrowcastingView";

export default function TSplitScreenBottom() {
    return (
        <SplitscreenGrid columns='2' rows='4'>
            <GridItem column = '1' row = 'span 2'>
                <NarrowcastingView screen={1}/>
            </GridItem>
            <GridItem column = '2' row = 'span 2'>
                <NarrowcastingView screen={2}/>
            </GridItem>
            <GridItem column = 'span 2' row = 'span 2'>
                <NarrowcastingView screen={0}/>
            </GridItem>
        </SplitscreenGrid>
    )
}