import { Box } from "@mui/material"

export default function SplitscreenGrid ({columns, rows, children}) {
    return(
        <Box sx={{
            backgroundColor: 'black',
            display: 'grid',
            gap: '0',
            height: '100vh',
            width: '100vw',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridTemplateRows: `repeat(${rows}, 1fr)`
        }}>
            {children}
        </Box>
    )
}