import YouTube from "react-youtube";

let loopTimeout
const onReady = event => {
    event.target.playVideo();
}
const onPause = () => {
    clearTimeout(loopTimeout);
}
const onPlay = event => {
    if (event.target.getPlayerState() === 1) {
        loopTimeout = setTimeout(()=>{
            event.target.seekTo(0);
        }, (event.target.getDuration() - event.target.getCurrentTime()) * 1000 - 1000)
    }
}
export default function YoutubePlayer({view}) {
    const options = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoPlay: 1,
            mute: 1,
            controls: 0,
            rel: 0,
            playsinline: 0
        },
    };
    return(
        
        <YouTube style={{display: "flex", height: '100%', width: '100%'}} videoId={view.video.split("=")[1]} opts={options} onReady={onReady} onPause={onPause} onPlay={onPlay}/>
    )
}