import { Box } from "@mui/material";
import { useContext } from "react";
import { ViewContext } from "../contexts/viewContext";
import YoutubePlayer from "./youtubePlayer";

export default function NarrowcastingView({screen}) {
  const {aspect, views} = useContext(ViewContext);
  return (
    <>
      {views[screen]?.content_type === "Youtube" && (
        <YoutubePlayer view={views[screen]}/>
      )}
      {views[screen]?.content_type === "Video" && (
        <video key={views[screen].id} loop autoPlay muted disablePictureInPicture width={'100%'} height={'100%'} style={{ objectFit: aspect? "contain" : "fill" }}>
          <source key={`source-${views[screen].id}`} src={views[screen].video} />
        </video>
      )}
      {views[screen]?.content_type === "Image" && (
        <Box component="img" sx={{height: "100%", width: "100%", objectFit: aspect? "contain" : "fill" }} alt="image" src={views[screen]?.image}/>
      )}
    </>
  );
}

/* youtube iframe
<iframe 
  width="100%" 
  height="100%" 
  objectfit= {aspect? "contain" : "fill"}
  src= {`https://www.youtube.com/embed/${views[screen].video.split("=")[1]}?&autoplay=1&loop=1&mute=1&controls=0&rel=0&widget_referrer&playlist=${views[screen].video.split("=")[1]}`} 
  title="Embedded YouTube" 
  frameBorder="0"
  referrerPolicy="no-refferer"
/> 

/* example for maps iframe
<iframe 
  width="100%" 
  height="100%" 
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2393.073380191118!2d6.438270877223581!3d53.144777589785654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c8340e43ec01e1%3A0x3693757e61281973!2sHunter-Retail!5e0!3m2!1snl!2snl!4v1728394458742!5m2!1snl!2snl" 
  title="maps" 
  referrerPolicy="no-refferer"
/> */