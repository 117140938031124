import SplitscreenGrid from "../splitscreenGrid";
import GridItem from "../gridItem";
import NarrowcastingView from "../narrowcastingView";

export default function HorizontalSplitScreen() {
    return (
        <SplitscreenGrid columns='2' rows='4'>
            <GridItem column='span 2' row='span 2'>
                <NarrowcastingView screen={0}/>
            </GridItem>
            <GridItem column='span 2' row='span 2'>
                <NarrowcastingView screen={1}/>
            </GridItem>
        </SplitscreenGrid>
    )
}