import Fullscreen from "./layouts/fullscreen";
import VerticalSplitScreen from "./layouts/verticalSplitScreen";
import HorizontalSplitScreen from "./layouts/horizontalSplitScreen";
import CrossSplitScreen from "./layouts/crossSplitScreen";
import TSplitScreenLeft from "./layouts/TSplitScreenLeft";
import TSplitScreenRight from "./layouts/TSplitScreenRight";
import TSplitScreenTop from "./layouts/TSplitScreenTop";
import TSplitScreenBottom from "./layouts/TSplitScreenBottom";


const layoutSwitch = (layout) => {
    switch (layout) {
        case "fullscreen":
            return <Fullscreen />;

        case "vertical-split-screen":
            return <VerticalSplitScreen />;

        case "horizontal-split-screen":
            return <HorizontalSplitScreen />;

        case "cross-split-screen":
            return <CrossSplitScreen />;

        case "t-split-screen-left":
            return <TSplitScreenLeft />;

        case "t-split-screen-right":
            return <TSplitScreenRight />;

        case "t-split-screen-top":
            return <TSplitScreenTop />;

        case "t-split-screen-bottom":
            return <TSplitScreenBottom />;
            
        default:
            return <Fullscreen />;
    }
}

export default function LayoutManager({ layout }) {
    return (
        layoutSwitch(layout)
    )
}